@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/scss/variables";

body
{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
}

body,html,#root,.cbox
{
  min-height: 100vh;
}


.solo-center
{
  min-height: 100%;
}

.wrapped
{
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.cbox
{
  display: flex;
  flex-direction: column;
  .header
  {
    height: 60px;
    background-color: beige;  
  }

  .body
  {
    flex:1;
  }

  
  
  .footer
  {
    height: 60px;
    background-color: antiquewhite; 
    text-align: center;
    padding:20px;
    color:#ccc; 
  }
}

.std-hr
{
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  height:10px;
}

.title
{
    color:$blue1;
}

// .yml-code
// {
//   max-height: 300px!important;
//   overflow-y: scroll;
// }

.button-line
{
  > *
  {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

  